import React from 'react'
import { graphql } from 'gatsby'
import { ContactSection } from '../components/sections/contact-section'
import Layout from '../components/layout'
import Container from '../components/container'


export const query = graphql`
  query ContactPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
        contactPhotoImage {
            asset {
                fluid {
                    ...GatsbySanityImageFluid
                }
            }
        }
        contactName,
        contactFunction,
        contactPhone,
        contactEmail
	},
	page: sanityContactPage {
		id
        slug {
          current
        }
        showBanner
        seoTitle
        seoKeywords
        seoDescription
        bannerTitle
        hideOverlay
        bannerSubTitle
        bannerImage {
          asset {
            url
            fluid {
				...GatsbySanityImageFluid
			}
          }
        }
        bannerCta {
          label
          url
        }
	}
  }
`

const ContactPage = ({ data, errors }) => {

    return (
        <Layout page={data.page || {}}>
            <Container>
                <ContactSection {...data.site} />
            </Container>
        </Layout>
    )
}

export default ContactPage